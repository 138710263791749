<template>
    <BT-Blade-Items
        addBladeName="release-archive"
        bladeName="release-archives"
        :bladesData="bladesData"
        :getParams="bladeData => { return { includeDetails: false, departureLocationID: bladeData.data != null ? bladeData.data.locationID : null }}"
        :headers="[
            { text: 'Location', value: 'departureLocationID', display: true, subtitle: 1 },
            { text: 'Courier', value: 'courierID', display: true, subtitle: 2 },
            { text: 'Released On', value: 'releasedOn', textFilter: 'toShortDateAndTime', subtitle: 3 }]"
        hideActions
        :itemProperties="['ID', 'DepartureLocationID', 'CourierID', 'SellerID', 'ReleasedOn']"
        minimizeOnSelect
        navigation="release-archives"
        title="Archived Releases"
        useServerPagination>
        <template v-slot:departureLocationID="{ item }">
            <BT-Entity 
                navigation="locations"
                :itemValue="item.departureLocationID"
                itemText="locationName" />
        </template>
        <template v-slot:courierID="{ item }">
            <div v-if="item.courierID == companyAccountID()">
                Self-Couriered
            </div>
            <BT-Entity
                v-else
                navigation="couriers"
                :itemValue="item.courierID"
                itemText="companyName" />
        </template>
    </BT-Blade-Items>
</template>

<script>
export default {
    name: 'Release-Archives-Blade',
    props: {
        bladesData: {
            type: Object,
            default: null
        },
    }
}
</script>