var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BT-Blade-Items',{attrs:{"addBladeName":"release-archive","bladeName":"release-archives","bladesData":_vm.bladesData,"getParams":function (bladeData) { return { includeDetails: false, departureLocationID: bladeData.data != null ? bladeData.data.locationID : null }},"headers":[
        { text: 'Location', value: 'departureLocationID', display: true, subtitle: 1 },
        { text: 'Courier', value: 'courierID', display: true, subtitle: 2 },
        { text: 'Released On', value: 'releasedOn', textFilter: 'toShortDateAndTime', subtitle: 3 }],"hideActions":"","itemProperties":['ID', 'DepartureLocationID', 'CourierID', 'SellerID', 'ReleasedOn'],"minimizeOnSelect":"","navigation":"release-archives","title":"Archived Releases","useServerPagination":""},scopedSlots:_vm._u([{key:"departureLocationID",fn:function(ref){
        var item = ref.item;
return [_c('BT-Entity',{attrs:{"navigation":"locations","itemValue":item.departureLocationID,"itemText":"locationName"}})]}},{key:"courierID",fn:function(ref){
        var item = ref.item;
return [(item.courierID == _vm.companyAccountID())?_c('div',[_vm._v(" Self-Couriered ")]):_c('BT-Entity',{attrs:{"navigation":"couriers","itemValue":item.courierID,"itemText":"companyName"}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }